import '../css/product.css';
import { Link } from 'react-router-dom';
import useFetch from '../api/useFetch';
import usePost from '../api/usePost';
import InventoryForm from './InventoryForm';
import ProductForm from './ProductForm';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Loading from './Loading';

const Product = ({ notify }) => {
    const { id } = useParams();
    const [inventoryFormVisible, setInventoryFormVisible] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [productFormVisible, setProductFormVisible] = useState(false);
    const { result: product, isLoading: productLoading, error: productError } = useFetch(`/product/${id}`,[refresh]);
    const { postData, error } = usePost();
    const [selectedTab, setSelectedTab] = useState('orders');

    const copyClick = (event) => {
        const content = event.target.textContent;
        navigator.clipboard.writeText(content);
        notify('Data Copied')
      };

      const blacklist = async () => {
        const data = {product_id: product.id}
        const response = await postData('/blacklist', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            setRefresh(!refresh)
            notify(response.message)
        }
      }

      const mark_unavailable = async () => {
        const data = {product_id: product.id}
        const response = await postData('/mark_unavailable', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            setRefresh(!refresh)
            notify(response.message)
        }
      }

      const delete_listings = async () => {
        const data = {product_id: product.id}
        const response = await postData('/delete_listings', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            setRefresh(!refresh)
            notify(response.message)
        }
      }


  return (
    <>
    {productLoading && <><Loading /></>}
    {productError && <div>{productError}</div>}
    {!productLoading && product && (<>
        <div class="product-infos">
            <div class="main-product-infos">
                <div class="product-image">
                <img src={product.image_url} alt={product.name}/>
                </div>
                <div class="product-info-line">
                <h2><i class="fas fa-book"></i>Title</h2>
                <h3>{product.name.length > 75  ? product.name.substring(0, 70).concat(" (...)") : product.name}</h3>
                </div>
                <div class="product-info-line">
                    <h2><i class="fas fa-barcode"></i>ASIN</h2>
                    <h3 onClick = {copyClick} class="copy-click">{product.asin}</h3>
                </div>
                <div class="product-info-line">
                    <h2><i class="fas fa-barcode"></i>EAN</h2>
                    <h3 onClick = {copyClick} class="copy-click">{product.ean}</h3>
                </div>
                <div class="product-info-line">
                    <h2><i class="fas fa-envelope"></i>Available</h2>
                    <h3>{product.available ? "Yes" : "No"}</h3>
                </div>
                <div class="product-info-line">
                    <h2><i class="fas fa-weight-hanging"></i>Weight</h2>
                    <h3>{product.weight * 1000} g / {Number(product.weight * 2.20462).toFixed(2)} lbs</h3>
                </div>
                <div class="product-info-line">
                    <h2><i class="fas fa-book"></i>In Stock</h2>
                    <h3>{product.in_stock_martelle ? "Yes" : "No"}</h3>
                </div>
                <div class="product-info-line">
                    <h2><i class="fas fa-box"></i>Supplier(s)</h2>
                    <h3>{product.suppliers.map(supplier => supplier.name).join(', ')}</h3>
                </div>
                <div class="product-info-line">
                    <h2><i class="fas fa-search"></i>Sourcing Price</h2>
                    <h3>{product.sourcing_price} {product.sourcing_marketplace.currency}</h3>
                </div>
                <div class="product-info-line">
                    <h2><i class="fas fa-hashtag"></i>Segment</h2>
                    <h3>{product.segment}</h3>
                </div>
                <div class="product-info-line">
                    <h2><i class="fas fa-search"></i>{product.sourcing_marketplace.name}</h2>
                    <h3><Link to={`https://www.librairiemartelle.com/livre/${product.ean}`} target="_blank"><i class="fas fa-link"></i></Link></h3>
                </div>
                <div class="product-info-line">
                    <h2><i class="fas fa-search"></i>Amazon</h2>
                    <h3><Link to={`https://www.amazon.com/dp/${product.asin}/`} target="_blank"><i class="fas fa-link"></i></Link></h3>
                </div>
            </div>
            <div class='right-container'>
                <div class="main-infos">
                    <div class={`main-info ${selectedTab === 'orders' ? 'active' : ''}`} onClick={() => setSelectedTab('orders')}>Orders</div>
                    <div class={`main-info ${selectedTab === 'availability' ? 'active' : ''}`} onClick={() => setSelectedTab('availability')}>Availability</div>
                    <div class={`main-info ${selectedTab === 'listings' ? 'active' : ''}`} onClick={() => setSelectedTab('listings')}>Listings</div>
                    <div class={`main-info ${selectedTab === 'inventory' ? 'active' : ''}`} onClick={() => setSelectedTab('inventory')}>Inventory</div>
                </div>

            <div class="product-data">
            { selectedTab === 'orders' && product.orders.length === 0 && (<div class='title'>NO ORDERS YET</div>)}
            { selectedTab === 'orders' && product.orders.length > 0 && (
                <div className="orders">
                    <div className="table">
                        <div className="table-header">
                            <div className="date">Order Date</div>
                            <div className="order_id">Status</div>
                            <div className="price">Received</div>
                            <div className="action">Details</div>
                        </div>
                        <div style={{height: "50vh", overflowY: "auto"}} className="table-body">
                            {product.orders.map((order) => (
                                <div className="table-row">
                                <div className="date">{order.purchase_date}</div>
                                <div className="order_id">{order.internal_status}</div>
                                <div className="action">${order.net_revenue_usd}</div>
                                <div className="action">
                                <Link to={`/order/${order.id}`}>
                                    <i className="fas fa-eye"></i>
                                </Link>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>)}
                { selectedTab === 'availability' && product.product_availability_logs.length === 0 && (<div class='title'>NO ORDERS YET</div>)}
            { selectedTab === 'availability' && product.product_availability_logs.length > 0 && (
                <div className="orders">
                    <div className="table">
                        <div className="table-header">
                            <div className="date">Date</div>
                            <div style={{width: "100%"}}>Change</div>
                        </div>
                        <div style={{height: "50vh", overflowY: "auto"}} className="table-body">
                            {product.product_availability_logs.map((log) => (
                                <div className="table-row">
                                    <div className="date">{log.update.substring(0,10)}</div>
                                    <div style={{width: "100%"}}>{log.change}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>)}
                { selectedTab === 'listings' && product.listings.length > 0 && (
                <div className="orders">
                    <div className="table">
                        <div className="table-header">
                            <div className="small">Marketplace</div>
                            <div className="small">Price</div>
                            <div className="small">Margin</div>
                            <div className="small">Details</div>
                        </div>
                        <div style={{height: "50vh", overflowY: "auto"}} className="table-body">
                            {product.listings.map((listing) => (
                                <div className="table-row">
                                    <div className="small"><Link to={`https://${listing.marketplace.domain}/dp/${product.asin}`} target="_blank">{listing.marketplace.domain}</Link></div>
                                    <div className="small">{parseFloat(listing.price).toFixed(2)} {listing.currency}</div>
                                    <div className="small">{parseFloat(listing.margin_usd).toFixed(2)} {listing.currency} ({100*parseFloat(listing.margin_percent)}%)</div>
                                    <div className="small"><Link to={`/listing/${listing.id}`}><i className="fas fa-eye"></i></Link></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>)}
                { selectedTab === 'inventory' && product.inventory_items.length > 0 && (
                <div className="orders">
                    <div className="table">
                        <div className="table-header">
                            <div className="date">Location</div>
                            <div className="date">Cost</div>
                        </div>
                        <div style={{height: "50vh", overflowY: "auto"}} className="table-body">
                            {product.inventory_items.map((item) => (
                                <div className="table-row">
                                    <div className="date">{item.location}</div>
                                    <div className="date">{item.acquisition_cost}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>)}
            </div>
            <div className="product-buttons">
                <div className="button" onClick={() => setProductFormVisible(true)}><i class="fas fa-edit"></i>Update</div>
                <div className="button" onClick={() => setInventoryFormVisible(true)}><i className="fas fa-warehouse"></i>Inventory</div>
                {!product.blacklisted && <div className="button" onClick={() => blacklist()}><i className="fas fa-ban"></i>Blacklist</div>}
                {product.blacklisted && <div className="button" onClick={() => blacklist()}><i className="fas fa-check-circle"></i>Whitelist</div>}
                {product.available && <div className="button" onClick={() => mark_unavailable()}><i class="fas fa-edit"></i>Unavailable</div>}
                {product.listings.length > 0 && <div className="button" onClick={() => delete_listings()}><i class="fas fa-trash"></i>Unlist</div>}
            </div>
            </div>
        </div>
    </>)}
    {inventoryFormVisible && (<><InventoryForm notify = {notify} visible={inventoryFormVisible} setVisible={setInventoryFormVisible} productId={product.id} orders={product.orders}/><div className="bg-disabled"></div></>)}
    {productFormVisible && (<><ProductForm notify = {notify} visible={productFormVisible} setVisible={setProductFormVisible} product={product}/><div className="bg-disabled"></div></>)}
    </>
  )
}

export default Product