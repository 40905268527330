import { useState, useEffect } from 'react';
import DashboardItemMetric from './DashboardItemMetric'
import useFetch from '../api/useFetch'
import '../css/dashboard.css';
import '../css/metrics.css';
import Loading from './Loading';
import LineChart from './LineChart';
import BarChart from './BarChart';

const Metric = () => {
const [View, setView] = useState('sales')
const [HistoryView, setHistoryView] = useState('current')
const [metricMarketplace, setMetricMarketplace] = useState("total")

const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const [startDate, setStartDate] = useState(oneMonthAgo.toISOString().split('T')[0]);
const [endDate, setEndDate] = useState(yesterday.toISOString().split('T')[0]);
const { result: metrics, isLoading: metricsLoading, error: metricsError } = useFetch(View === 'sales' ? '/sales_metrics' : (View === 'listings' ? (HistoryView === 'current' ? '/inventory_metrics' : '/inventory_metrics_history') : '/sales_metrics'),[View,HistoryView]);
const [parsedMetrics, setParsedMetrics] = useState(null)
const [chartData, setChartData] = useState(null)
const [historyMetricFilter,setHistoryMetricFilter] = useState("total")
const handleViewClick = (view) => {
    setView(view)
    setMetricMarketplace("total")
  }

  const handleMarketplaceClick = (marketplace) => {
    metricMarketplace !== marketplace ? setMetricMarketplace(marketplace) : setMetricMarketplace("total")
  }

  const handlehistoryMetricFilterClick = (filter) => {
    setHistoryMetricFilter(filter)
  }

  useEffect(() => {
    metrics && metrics.parameters && View === 'listings' && HistoryView === 'current' && setParsedMetrics(JSON.parse(metrics.parameters.replace(/'/g, "\"").replace(/\./g, "_")))
    if (HistoryView === 'evolution' && Array.isArray(metrics)) {
      let data =  {
        title: 'Total Listings',
        label: 'Sample Data',
        labels: [], //
        data: [],
      }
        metrics.forEach(item => {
          const updateTime = new Date(item.update_time);
          const formattedDateTime = updateTime.toLocaleString();
          // const inventoryNumber = parseFloat(item.value);
          const inventoryData = JSON.parse(item.parameters.replace(/'/g, "\"").replace(/\./g, "_"))
          if (inventoryData.hasOwnProperty(metricMarketplace)) {
          data.labels.push(formattedDateTime);
          if (historyMetricFilter === 'total'){
            data.data.push(inventoryData[metricMarketplace]['total_products_processed']);
          }
          if (historyMetricFilter === 'active'){
            data.data.push(inventoryData[metricMarketplace]['posted_inventory_accepted_count']);
          }
          if (historyMetricFilter === 'no_competition'){
            data.data.push(inventoryData[metricMarketplace]['posted_no_competition']);
          }
          if (historyMetricFilter === 'best_price'){
            data.data.push(inventoryData[metricMarketplace]['posted_inventory_best_price_count']);
          }
          if (historyMetricFilter === 'approval_needed'){
            data.data.push(inventoryData[metricMarketplace]['posted_inventory_approval_needed']);
          }
          if (historyMetricFilter === 'above_competition'){
            data.data.push(inventoryData[metricMarketplace]['posted_inventory_above_competition_limit']);
          }
          if (historyMetricFilter === 'no_sourcing'){
            data.data.push(inventoryData[metricMarketplace]['posted_inventory_no_sourcing']);
          }
        }
        });
        setChartData(data)
      }
      },[metrics, View, HistoryView,metricMarketplace, historyMetricFilter])



  return (
    <>
            <div className="main-infos">
            <div className={`main-info ${View === 'sales' ? 'active' : ''}`} onClick={() => handleViewClick('sales')}>
            <i className="fas fa-cash-register"></i>Sales
            </div>
            <div className={`main-info ${View === 'logistic' ? 'active' : ''}`} onClick={() => handleViewClick('logistic')}>
            <i className="fas fa-shipping-fast"></i>Logistic
            </div>
            <div className={`main-info ${View === 'sourcing' ? 'active' : ''}`} onClick={() => handleViewClick('sourcing')}>
            <i className="fas fa-shopping-basket"></i>Sourcing
            </div>
            <div className={`main-info ${View === 'listings' ? 'active' : ''}`} onClick={() => handleViewClick('listings')}>
            <i className="fas fa-warehouse"></i>Listings
            </div>
            <div className={`main-info ${View === 'customer service' ? 'active' : ''}`} onClick={() => handleViewClick('customer service')}>
            <i className="fas fa-headset"></i>Quality
            </div>
        </div>
    <div className="metrics">
      <div className="metrics-data">
        {View === 'listings' && 
          <div className="tabs">
              <div className={`tab ${HistoryView === 'current' && 'active-tab'}`} onClick={() => setHistoryView('current')}>CURRENT</div>
              <div className={`tab ${HistoryView === 'evolution' && 'active-tab'}`}  onClick={() => setHistoryView('evolution')}>EVOLUTION</div>
          </div>}
        {metricsLoading && <div><Loading /> </div>}
        {metricsError && <div>{metricsError}</div>}
        {!metricsLoading && metrics && View === 'sales' && 
        <>
          <div className="buttons">
            <div className={`button  ${metricMarketplace === 'amazon_com' ? 'active' : ''}`} onClick={() => handleMarketplaceClick('amazon_com')}><i className="fab fa-amazon"></i>Amazon USA</div>
            <div className={`button ${metricMarketplace === 'amazon_ca' ? 'active' : ''}`} onClick={() => handleMarketplaceClick('amazon_ca')}><i className="fab fa-amazon"></i>Amazon Canada</div>
            <div className={`button ${metricMarketplace === 'amazon_mx' ? 'active' : ''}`} onClick={() => handleMarketplaceClick('amazon_mx')}><i className="fab fa-amazon"></i>Amazon Mexico</div>
          </div>
          <div className="date-picker">
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="date-picker">
            <label>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        <BarChart chartData={metrics[metricMarketplace]} width={1000} height={500}/>
        </>
        }
        {!metricsLoading && metrics && View === 'listings' && 
        <>
          <div className="buttons">
            <div className={`button  ${metricMarketplace === 'amazon_com' ? 'active' : ''}`} onClick={() => handleMarketplaceClick('amazon_com')}><i className="fab fa-amazon"></i>Amazon USA</div>
            <div className={`button ${metricMarketplace === 'amazon_ca' ? 'active' : ''}`} onClick={() => handleMarketplaceClick('amazon_ca')}><i className="fab fa-amazon"></i>Amazon Canada</div>
            <div className={`button ${metricMarketplace === 'amazon_mx' ? 'active' : ''}`} onClick={() => handleMarketplaceClick('amazon_mx')}><i className="fab fa-amazon"></i>Amazon Mexico</div>
          </div>
          {HistoryView === 'current' && (
          <div className="metrics-content">
            <div className="metrics-row">
              <DashboardItemMetric data={"listings"} value={parsedMetrics && (metricMarketplace === 'amazon_com' ? parsedMetrics.amazon_com.total_products_processed :  metricMarketplace === 'amazon_ca' ? parsedMetrics.amazon_ca.total_products_processed : parsedMetrics.amazon_ca.total_products_processed + parsedMetrics.amazon_com.total_products_processed)} time_choice ={false}/>
            </div>
            <div className="metrics-row">
              <DashboardItemMetric data={"listings"} text={"Active Listings"} value={parsedMetrics && (metricMarketplace === 'amazon_com' ? parsedMetrics.amazon_com.posted_inventory_accepted_count :  metricMarketplace === 'amazon_ca' ? parsedMetrics.amazon_ca.posted_inventory_accepted_count : parsedMetrics.amazon_ca.posted_inventory_accepted_count + parsedMetrics.amazon_com.posted_inventory_accepted_count)} time_choice ={false}/>
              <DashboardItemMetric data={"listings"} text={"No Competition Listings"} value={parsedMetrics && (metricMarketplace === 'amazon_com' ? parsedMetrics.amazon_com.posted_no_competition :  metricMarketplace === 'amazon_ca' ? parsedMetrics.amazon_ca.posted_no_competition : parsedMetrics.amazon_ca.posted_no_competition + parsedMetrics.amazon_com.posted_no_competition)} time_choice ={false}/>
              <DashboardItemMetric data={"listings"} text={"Best Price Listings"} value={parsedMetrics && (metricMarketplace === 'amazon_com' ? parsedMetrics.amazon_com.posted_inventory_best_price_count :  metricMarketplace === 'amazon_ca' ? parsedMetrics.amazon_ca.posted_inventory_best_price_count : parsedMetrics.amazon_ca.posted_inventory_best_price_count + parsedMetrics.amazon_com.posted_inventory_best_price_count)} time_choice ={false}/>
            </div>
            <div className="metrics-row">
              <DashboardItemMetric data={"listings"} text={"Approval Needed Listings"} value={parsedMetrics && (metricMarketplace === 'amazon_com' ? parsedMetrics.amazon_com.posted_inventory_approval_needed :  metricMarketplace === 'amazon_ca' ? parsedMetrics.amazon_ca.posted_inventory_approval_needed : parsedMetrics.amazon_ca.posted_inventory_approval_needed + parsedMetrics.amazon_com.posted_inventory_approval_needed)} time_choice ={false}/>
              <DashboardItemMetric data={"listings"} text={"Above Competition Listings"} value={parsedMetrics && (metricMarketplace === 'amazon_com' ? parsedMetrics.amazon_com.posted_inventory_above_competition_limit :  metricMarketplace === 'amazon_ca' ? parsedMetrics.amazon_ca.posted_inventory_above_competition_limit : parsedMetrics.amazon_ca.posted_inventory_above_competition_limit + parsedMetrics.amazon_com.posted_inventory_above_competition_limit)} time_choice ={false}/>
              <DashboardItemMetric data={"listings"} text={"No Sourcing Listings"}  value={parsedMetrics && (metricMarketplace === 'amazon_com' ? parsedMetrics.amazon_com.posted_inventory_no_sourcing :  metricMarketplace === 'amazon_ca' ? parsedMetrics.amazon_ca.posted_inventory_no_sourcing : parsedMetrics.amazon_ca.posted_inventory_no_sourcing + parsedMetrics.amazon_com.posted_inventory_no_sourcing)} time_choice ={false}/>
            </div>
          </div>)}
          {HistoryView === 'evolution' && chartData && chartData.labels.length > 0 && (
          <>
          <LineChart chartData={chartData} />
          <div className="buttons">
            <div className={`button  ${historyMetricFilter === 'total' ? 'active' : ''}`} onClick={() => handlehistoryMetricFilterClick('total')}>Total</div>
            <div className={`button ${historyMetricFilter === 'active' ? 'active' : ''}`} onClick={() => handlehistoryMetricFilterClick('active')}>Active</div>
            <div className={`button ${historyMetricFilter === 'no_competition' ? 'active' : ''}`} onClick={() => handlehistoryMetricFilterClick('no_competition')}>No competition</div>
            <div className={`button ${historyMetricFilter === 'best_price' ? 'active' : ''}`} onClick={() => handlehistoryMetricFilterClick('best_price')}>Best Price</div>
            <div className={`button ${historyMetricFilter === 'approval_needed' ? 'active' : ''}`} onClick={() => handlehistoryMetricFilterClick('approval_needed')}>Approval Needed</div>
            <div className={`button ${historyMetricFilter === 'above_competition' ? 'active' : ''}`} onClick={() => handlehistoryMetricFilterClick('above_competition')}>Above Competition</div>
            <div className={`button ${historyMetricFilter === 'no_sourcing' ? 'active' : ''}`} onClick={() => handlehistoryMetricFilterClick('no_sourcing')}>No Sourcing</div>
          </div>
          </>
          )}
          </>}
</div>
    </div>
    </>
  )
}

export default Metric