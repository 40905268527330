import '../css/order.css';
import { useState } from 'react';

import Orders from "./Orders";

const Quality = ({ notify }) => {
    const [View, setView] = useState('requests');

    const handleViewClick = (view) => {
        setView(view);
    };

    const [ClientName, setClientName] = useState('');
    const [BookName, setBookName] = useState('');

    const copyClick = (event) => {
      const content = event.target.textContent;
      navigator.clipboard.writeText(content);
      notify('Data Copied')
    };

    return (
        <>
            <div className="main-infos">
                <div className={`main-info ${View === 'requests' ? 'active' : ''}`} onClick={() => handleViewClick('requests')}><i className="fas fa-question-circle"></i>Requests</div>
                <div className={`main-info ${View === 'feedback' ? 'active' : ''}`} onClick={() => handleViewClick('feedback')}><i className="fas fa-info-circle"></i>Feedback</div>
                <div className={`main-info ${View === 'az_claims' ? 'active' : ''}`} onClick={() => handleViewClick('az_claims')}><i className="fas fa-headset"></i>AZ Claims</div>
                <div className={`main-info ${View === 'templates' ? 'active' : ''}`} onClick={() => handleViewClick('templates')}><i className="fas fa-headset"></i>Templates</div>
            </div>
            {View === 'requests' && (<Orders showFilters={false} status='answers_needed' notify={notify}/>)}
            {View === 'feedback' && (<Orders showFilters={false} status='feedback_to_request' notify={notify}/>)}
            {View === 'az_claims' && (<Orders showFilters={false} status='az_claims' notify={notify}/>)}
            {View === 'templates' && (
                <div>
                    <input
                        type="text"
                        placeholder="Client Name"
                        value={ClientName}
                        onChange={(e) => setClientName(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Book Name"
                        value={BookName}
                        onChange={(e) => setBookName(e.target.value)}
                    />
                    <div className='template' onClick = {copyClick}>
                        {`Dear ${ClientName === '' ? '{{Client Name}}' : ClientName},

I hope this message finds you well. We are writing to you regarding your recent order with us.

We regret to inform you that due to an unexpectedly high demand for the book you ordered, "${BookName === '' ? '{{Book Name}}' : BookName}" we are currently out of stock and, unfortunately, will not be able to fulfill your order at this time. This is a rare situation that we did not anticipate, and we understand the inconvenience this may cause.

As a result, we need to cancel your current order. We sincerely apologize for any disappointment or inconvenience this may have caused you. We are working diligently to replenish our stock and would be more than happy to notify you as soon as "${BookName === '' ? '{{Book Name}}' : BookName}" becomes available again.

If you would like to be informed about the availability of the book, please reply to this email or contact our customer service team, and we will ensure you are among the first to know once it's back in stock. Alternatively, if there is another book or product you are interested in, our team is here to assist you in finding the perfect item.

We truly value your understanding and patience in this matter and look forward to serving you again in the future.

Warm regards,

Joe at Epytis`}
                    </div>
                </div>
            )}
        </>
    )
}

export default Quality;
