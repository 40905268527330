import usePost from '../api/usePost';
import { useState } from 'react';

function InventoryForm({notify, visible, setVisible, productId, orders}) {
    const { postData, isLoading, error } = usePost();
    const [formData, setFormData] = useState({location: '',  condition: 'N', use_sourcing_price: false, acquisition_cost: '', currency: 'EUR', product_id: productId, order_id: orders.length > 0 ? orders[0].id : null});

    const handleClose = () => {
        setVisible(false);
      };

      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
      
        // Handle checkboxes separately
        if (type === 'checkbox') {
          setFormData({ ...formData, [name]: checked });
        } else {
          // Check if the value is "None" and set formData to an empty string
          const newValue = value === 'None' ? '' : value;
          setFormData({ ...formData, [name]: newValue });
        }
      };      

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await postData('/create_inventory_item', formData);
        if (response) {
            setVisible(false);
            notify(response.message)
        }
      };
  return (
    visible && (
    <div id="purchase-form">
    <i id="close-purchase-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="condition">
          <i className="fas fa-store"></i>Condition:
        </label>
        <select id="condition" name="marketplace" value={formData.condition} onChange={handleChange}>
            <option value="N">New</option>
            <option value="UM">Used - Like New</option>
            <option value="UVG">Used - Very Good</option>
            <option value="UG">Used - Good</option>
            <option value="UA">Used - Acceptable</option>
      </select>
      </div>
      <div>
        <label htmlFor="location">
          <i className="fas fa-tag"></i>Location:
        </label>
        <input type="text" id="location" name="location" value={formData.location} onChange={handleChange}/>
      </div>
        {orders.length > 0 && (<div>
        <label htmlFor="order">
          <i className="fas fa-tags"></i>Related Order:
        </label>
        <select id="order" name="order" value={formData.order_id} onChange={handleChange}>
        <option value="">None</option>
        {orders.map((order) => <option value={order.id}>{order.marketplace_order_id} - {order.internal_status}</option>)}
      </select>
      </div>)}
      {orders.length === 0 && ( <>
      <div>
        <label htmlFor="acquisition-cost">
          <i className="fas fa-tags"></i>Acquisition Cost:
        </label>
        <input type="text" id="acquisition-cost" name="acquisition_cost" value={formData.acquisition_cost} onChange={handleChange}/>
      </div>
      <div>
        <label htmlFor="currency">
          <i className="fas fa-coins"></i>Currency:
        </label>
        <select id="purchase-currency" name="currency" value={formData.currency} onChange={handleChange}>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="JPY">JPY</option>
          <option value="GBP">GBP</option>
          <option value="AUD">AUD</option>
        </select>
      </div></>)}
      <div>
        <label htmlFor="use_sourcing_price">Use Sourcing Price</label>
        <input
          type="checkbox"
          id="use_sourcing_price"
          name="use_sourcing_price"
          checked={formData.use_sourcing_price}
          onChange={handleChange}
        />
      </div>
      <div>
        <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Save
        </button>
      </div>
    </form>
    {error && <p>Error: {error}</p>}
  </div>)
  )
}

export default InventoryForm