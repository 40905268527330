import '../css/finance.css';
import '../css/orders.css';
import React, { useState } from 'react';
import useFetch from '../api/useFetch';
import FinanceOrderLine from './FinanceOrderLine';

const Finance = ({ notify }) => {
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(null)
  const [filter, setFilter] = useState(null)
  const { result: orders, isLoading: ordersLoading, error: ordersError } = useFetch(`/orders_finance?page=${page}${order ? `&order=${order}` : ``}${filter ? `&filter=${filter}` : ``}`,[page, refresh]);

  const handleRefresh = () => {
    setRefresh(!refresh)
  }

  const handlePreviousClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < orders.total / 15){
      setPage(page + 1);}
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = (pages) => {
    const totalPages = pages;
    const pageNumbers = [];
    const ellipsis = <div>...</div>;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= page - 2 && i <= page + 2)) {
        pageNumbers.push(
          <div
            key={i}
            className={`number${page === i ? " active" : ""}`}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </div>
        );
      } else if (pageNumbers[pageNumbers.length - 1] !== ellipsis) {
        pageNumbers.push(ellipsis);
      }
    }

    return pageNumbers;
  };

  const handleOrderClick = (choice) => {
    if (choice === order) {
      setOrder(null)
      setPage(1)
    }
    else {
      setOrder(choice)
      setPage(1)
    }
  }

  const handleFilterClick = (choice) => {
    if (choice === filter) {
      setFilter(null)
      setPage(1)
    }
    else {
      setFilter(choice)
      setPage(1)
    }
  }

  // Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

  return (
    <>
    <div class="finance-metrics">
            <div class="finance-metric">
            <h1>Total Orders</h1>
            <h2>{orders && orders.total}</h2>
            </div>
            <div class="finance-metric">
            <h1>Total Revenue</h1>
            <h2>{orders && formatter.format(orders.total_revenue)}</h2>
            </div>
            <div class="finance-metric">
            <h1>Total Margin</h1>
            <h2>{orders && formatter.format(orders.total_margin)}</h2>
            </div>
            <div class="finance-metric">
            <h1>Average Revenue</h1>
            <h2>{orders && formatter.format(orders.average_revenue)}</h2>
            </div>
            <div class="finance-metric">
            <h1>Average Margin</h1>
            <h2>{orders && formatter.format(orders.average_margin)}</h2>
            </div>
            <div class="finance-metric">
            <h1>Average Margin %</h1>
            <h2>{orders && (orders.average_margin_percent * 100).toFixed(1)}%</h2>
            </div>
        </div>
    <div className="finance-filters">
      <div className={`filter ${filter === 'negative_margins' ? 'active' : ''}`} onClick={() => handleFilterClick('negative_margins')}>Negative Margins</div>
      <div className={`order ${order === 'margin_desc' ? 'active' : ''}`} onClick={() => handleOrderClick('margin_desc')}>Margin Descending</div>
      <div className={`order ${order === 'margin_percent_desc' ? 'active' : ''}`} onClick={() => handleOrderClick('margin_percent_desc')}>Margin Descending (%)</div>
      <div className={`order ${order === 'margin_asc' ? 'active' : ''}`} onClick={() => handleOrderClick('margin_asc')}>Margin Ascending</div>
      <div className={`order ${order === 'margin_percent_asc' ? 'active' : ''}`} onClick={() => handleOrderClick('margin_percent_asc')}>Margin Ascending (%)</div>
    </div>
    <div className="orders">
      <div className="title">
        ORDERS FINANCE
        <i className="fas fa-sync" onClick={handleRefresh}></i>
      </div>
      <div className="table">
        <div className="table-header">
          <div className="domain">Order Date</div>
          <div className="domain">Marketplace</div>
          <div className="order_id">Order Id</div>
          <div className="status">Internal Status</div>
          <div className="sourcing">Disbursed</div>
          <div className="status">Purchase(s)</div>
          <div className="price">Shipping</div>
          <div className="status">Expected</div>
          <div className="status">Received</div>
          <div className="status">Margin</div>
          <div className="status">Margin %</div>
          <div className="action">Details</div>
        </div>
        <div className="table-body">
            {ordersLoading && <div className='table-placeholder'></div>}
            {ordersError && <div>{ordersError}</div>}
            {!ordersLoading &&
              orders && orders.total > 0 &&
              orders.items.map((order) => (
                <FinanceOrderLine
                  key={order.id}
                  id={order.id}
                  date={order.purchase_date}
                  marketplace_domain={order.marketplace.domain}
                  has_disbursement={order.has_disbursement}
                  order_id={order.marketplace_order_id}
                  actual_purchase_cost={order.actual_purchase_cost}
                  actual_shipment_cost={order.actual_shipment_cost}
                  net_revenue={order.net_revenue}
                  actual_net_revenue={order.actual_net_revenue}
                  actual_net_profit={order.actual_net_profit}
                  internal_status={order.internal_status}
                  actual_net_profit_percent={order.actual_net_profit_percent ? order.actual_net_profit_percent : '-'}
                />
              ))}
          </div>
      </div>
    </div>
    {!ordersLoading && orders && orders.total > 12 && (
        <div className="pagination">
          <div className="number" onClick={handlePreviousClick}>
            <i className="fas fa-angle-left"></i>
          </div>
          {renderPageNumbers(parseInt(orders.total / 12) + 1)}
          <div className="number" onClick={handleNextClick}>
            <i className="fas fa-angle-right"></i>
          </div>
        </div>
      )}
    </>
  );
};

export default Finance;
