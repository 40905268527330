import usePost from '../api/usePost';
import { useState } from 'react';

function ProductForm({notify, visible, setVisible, product}) {
    const { postData, isLoading, error } = usePost();
    const [formData, setFormData] = useState({image_url: product.image_url,  weight: product.weight, product_id: product.id, name: product.name})
    const handleClose = () => {
        setVisible(false);
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('submitted')
        console.log(formData)
        const response = await postData('/update_product', formData);
        if (response) {
            setVisible(false);
            notify(response.message)
        }
      };
  return (
    visible && (
    <div id="purchase-form">
    <i id="close-purchase-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="location">
          <i className="fas fa-tag"></i>Image URL:
        </label>
        <input type="text" id="image_url" name="image_url" value={formData.image_url} onChange={handleChange}/>
      </div>
      <div>
        <label htmlFor="order">
          <i className="fas fa-tags"></i>Weight:
        </label>
        <input type="text" id="weight" name="weight" value={formData.weight} onChange={handleChange}/>
      </div>
      <div>
        <label htmlFor="order">
          <i className="fas fa-tags"></i>Weight:
        </label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange}/>
      </div>
      <div>
        <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Save
        </button>
      </div>
    </form>
    {error && <p>Error: {error}</p>}
  </div>)
  )
}

export default ProductForm