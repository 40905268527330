import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from "../api/useFetch";
import usePost from '../api/usePost';
import Loading from "./Loading";
import { Link } from 'react-router-dom';

const Script = ({ notify }) => {
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const { result: script, isLoading, error } = useFetch(`/script/${id}`, [refresh]);
  const { postData } = usePost();
  const [formData, setFormData] = useState({});
  const [showOutput, setShowOutput] = useState(false);
  const [batchId, setBatchId] = useState(null);

  useEffect(() => {
    if (script) {
      setFormData({ ...script, script_id: id });
    }
  }, [script, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await postData('/update_script', formData);
    if (response) {
      setRefresh(!refresh);
      notify('Script updated successfully');
    }
  };

  const runScript = async () => {
    const response = await postData(`/run_script`, { script_id: id });
    if (response && !Object.hasOwn(response, 'error')) {
      notify(response.message)
      setRefresh(!refresh);
  }
  if (Object.hasOwn(response, 'error')) {
      notify(response.error,"error")
  }
    
  };



  if (isLoading) return <Loading />;
  if (error) return <div>{error}</div>;

  return (<>
    <div className="script-container">
      <Link to="/scripts">Back to scripts list</Link>
      <h1>{script.name}</h1>
      <center>
      <div className="script-details">
        {
          <form onSubmit={handleSubmit} className="edit-form">
            <div className="form-group">
              <label>Name</label>
              <input style={{textAlign: 'center'}} type="text" name="name" value={formData.name} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Frequency</label>
              <select id="script-frequency" name="frequency" value={formData.frequency} onChange={handleChange}>
                <option value="maximal">Maximal</option>
                <option value="hourly">Hourly</option>
                <option value="daily">Daily</option>
                <option value="stopped">Stopped</option>
              </select>
            </div>
            <div className="form-group">
              <label>Category</label>
              <select id="script-category" name="category" value={formData.category} onChange={handleChange}>
                <option value="shipping">Shipping</option>
                <option value="orders">Orders</option>
                <option value="inventory">Inventory</option>
                <option value="finance">Finance</option>
                <option value="customer_service">Customer Service</option>
                <option value="reporting">Reporting</option>
              </select>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea style={{textAlign: 'center', width: '80%'}} name="description" value={formData.description} onChange={handleChange}></textarea>
            </div>
            <button type="submit" style={{marginTop: '15%', fontWeight: 'bold', backgroundColor: '#00253b', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer'}}><i className="fas fa-save"></i>  Save Changes</button>
          </form>}
      </div>
      <div className="script-actions">
        {<button onClick={runScript} className="action-button run-button">
            <i className="fas fa-play"></i>Run Script
          </button>}
      </div>
      {script.batches && script.batches.length > 0 && <div className="script-batches">
        <h2>Latest Batches</h2>
        
          {script.batches.length > 0 && script.batches.map((batch, index) => (
            <>
            <h4>{batch.start_time.substring(0,10)} - {batch.start_time.substring(11,19).split(':')[0] - 4 + ':' + batch.start_time.substring(11,19).split(':')[1]} - {batch.status} : {batch.output && batch.output.length < 100 ? batch.output : batch.output ? <span style={{cursor: 'pointer'}} onClick={() => {setShowOutput(true); setBatchId(index)}}>see output</span> : 'No Output'}</h4>
            </>
          ))}
      </div>}
      </center>
    </div>
    {showOutput && <div id="purchase-form">
    <i id="close-purchase-form" className="fas fa-times" onClick={() => setShowOutput(false)}></i>
    <h3>Output</h3>
    <textarea rows="25" cols="40" value={script.batches[batchId].output}></textarea>
  </div>}
    </>
  );
};

export default Script;