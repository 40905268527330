import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = ({ chartData, width, height }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (!chartData) {
      // Handle the case where chartData is not defined
      return;
    }
    const ctx = chartRef.current.getContext('2d');

    const labels = Object.keys(chartData);
    const data = Object.values(chartData);

    const chartConfig = {
      labels: labels,
      datasets: [
        {
          label: "Revenue",
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75,192,192,0.4)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: data,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: false
          },
          ticks: {
            stepSize: 150,
            callback: function (value, index, values) {
              return '$' + value;
            }
          }
        },
        x: {
          grid: {
            display: false
          },
        }
      },
      plugins: {
        legend: {
          display: false
        }
      },
      layout: {
        padding: {
          top: 10,
          bottom: 10,
          left: 10,
          right: 10
        }
      },
      responsive: true,
      maintainAspectRatio: true,
      animation: {
        duration: 0
      },
    };

    // Destroy previous chart instance if it exists
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Set canvas width and height based on props
    chartRef.current.width = width;
    chartRef.current.height = height;

    // Create new chart instance
    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: chartConfig,
      options: options
    });

    // Cleanup function
    return () => {
      // Destroy chart instance on component unmount
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartData, width, height]);

  return (
    <div className="revenue-metric">
      <div className="graph-title">Revenue</div>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default BarChart;
