import { Link } from 'react-router-dom';

const INTERNAL_STATUS = {
  to_buy: "Placed By Client - To Buy",
  pending_sourcing: "Pending - Sourcing Issue",
  pending_purchase_shipment: "Pending Purchase Shipment",
  pending_purchase_reception: "Pending Purchase Reception",
  to_pickup: "To Pick Up",
  received: "Received",
  anticipated_shipping: "Pre-Shipped",
  dummy_shipping: "Dummy-Shipped - Sourcing Issue",
  shipped: "Shipped",
  received_by_client: "Received by Client",
  canceled_by_client: "Canceled by Client",
  canceled_by_us: "Canceled - No Sourcing",
};

const FinanceOrderLine = ({
    id,
    date,
    marketplace_domain,
    order_id,
    internal_status,
    has_disbursement,
    actual_purchase_cost,
    actual_shipment_cost,
    net_revenue,
    actual_net_revenue,
    actual_net_profit,
    actual_net_profit_percent
}) => {
  return (
    <div className="table-row">
       <div className="domain">{date}</div>
        <div className="domain">{marketplace_domain}</div>
        <div className="order_id">{order_id}</div>
        <div className="status">{INTERNAL_STATUS[internal_status]}</div>
        <div className="sourcing">{has_disbursement ? "Y" : "N"}</div>
        <div className="status">${actual_purchase_cost}</div>
        <div className="price">${actual_shipment_cost}</div>
        <div className="status">${net_revenue}</div>
        <div className="status">{actual_net_revenue ? `$${actual_net_revenue}` : '-'}</div>
        <div className="status">{actual_net_profit ? `$${actual_net_profit}` : '-'}</div>
        <div className="status">{actual_net_profit_percent === '-' ? '-' : `${actual_net_profit_percent}%`}</div>
        <div className="action">
        <Link to={`/order/${id}`}>
          <i className="fas fa-eye"></i>
        </Link>
      </div>
    </div>
  );
};

export default FinanceOrderLine;
