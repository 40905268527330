import { useRef, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import useFetch from "../api/useFetch";
import Loading from "./Loading";
import ProductLine from "./ProductLine";
import SearchResultsContext from '../context/SearchTermContext';
import '../css/products.css'

const Products = ( ) => {
  const { searchTerm } = useContext(SearchResultsContext);
    console.log("search contxt:", searchTerm)
    const [productSearchTerm, setProductSearchTerm]  = useState(searchTerm)
    const searchInputRef = useRef(null);
    const navigate = useNavigate();

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setProductSearchTerm(searchInputRef.current.value);
          searchInputRef.current.value = '';
      };
      const [page, setPage] = useState(1);
      const { result: products, isLoading: productsLoading, error: productsError } = useFetch(`/products?page=${page}&search_term=${productSearchTerm}`,[productSearchTerm]);
      
      if (products && products.total === 1) {
        let id = products.items[0].id
        navigate(`/product/${id}`);
      }

      const handlePreviousClick = () => {
        if (page > 1) {
          setPage(page - 1);
        }
      };

      const handleNextClick = () => {
        if (page < products.total / 15){
          setPage(page + 1);}
      };
    
      const handlePageClick = (pageNumber) => {
        setPage(pageNumber);
      };
    
      const renderPageNumbers = (pages) => {
        const totalPages = pages;
        const pageNumbers = [];
        const ellipsis = <div>...</div>;
    
        for (let i = 1; i <= totalPages; i++) {
          if (i === 1 || i === totalPages || (i >= page - 2 && i <= page + 2)) {
            pageNumbers.push(
              <div
                key={i}
                className={`number${page === i ? " active" : ""}`}
                onClick={() => handlePageClick(i)}
              >
                {i}
              </div>
            );
          } else if (pageNumbers[pageNumbers.length - 1] !== ellipsis) {
            pageNumbers.push(ellipsis);
          }
        }
    
        return pageNumbers;
      };

  return (
    <>
    <div className="products-top">
    <form className="search-container" onSubmit={handleSearchSubmit}>
    <input type="text" placeholder="Search Product.." ref={searchInputRef} />
    <button className="search-btn" type="submit">
      <i className="fa fa-search"></i>
    </button>
  </form>
  </div>
  <div className="products">
   <div className="table">
    <div className="table-header">
      <div className="small">EAN</div>
      <div className="small">ASIN</div>
      <div className="small">Segment</div>
      <div className="large">Name</div>
      <div className="small">Sales #</div>
      <div className="small">Revenue $</div>
      <div className="small">Margin $</div>
      <div className="small">Inventory</div>
      <div className="small">Listings</div>
      <div className="small">Blacklisted</div>
      <div className="small">Details</div>
    </div>
    <div className="table-body">
            {productsLoading && <div className='table-placeholder'><Loading /></div>}
            {productsError && <div>{productsError}</div>}
            {!productsLoading &&
              products &&
              products.items.map((product) => (
                <ProductLine
                id= {product.id}
                ean = {product.ean}
                asin = {product.asin}
                segment = {product.segment}
                product_name = {product.name}
                nb_orders = {product.nb_orders === null ? 0 : product.nb_orders}
                total_revenue = {product.total_revenue === null ? 0 : product.total_revenue}
                total_margin = {product.total_margin === null ? 0 : product.total_margin}
                has_inventory = {product.has_inventory ? "yes" : "no"}
                has_listings = {product.listings.length > 0 ? "yes" : "no"}
                blacklisted = {product.blacklisted ? "yes" : "no"}
                />
              ))}
          </div>
    </div>
   </div>
   {!productsLoading && products && products.total > 15 && (
        <div className="pagination">
          <div className="number" onClick={handlePreviousClick}>
            <i className="fas fa-angle-left"></i>
          </div>
          {renderPageNumbers(parseInt(products.total / 15) + 1)}
          <div className="number" onClick={handleNextClick}>
            <i className="fas fa-angle-right"></i>
          </div>
        </div>
      )}
   </>
  )
}

export default Products