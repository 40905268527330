import { useState } from "react";
const DashboardItemMetric = ({ data, value, time_choice, text, variation }) => {
    const [showTimeRange, setShowTimeRange] = useState(false);
    const [TimeRange, setTimeRange] = useState("Today");

    const toggleTimeRange = () => {
        setShowTimeRange(!showTimeRange);
        };

    let icon = null
    let unit = null
    let timeRange = null
    let conversion = null
    let round = null
    if (data === "today_revenue") {
        icon = "fas fa-money-bill"
        unit = "USD"
        text = text || "Revenue"
        timeRange = TimeRange
        conversion = 1
        round = 2
    }

    if (data === "todays_order_nb") {
        icon = "fas fa-shopping-basket"
        unit = "Units"
        text = text || "Orders"
        timeRange = TimeRange
        conversion = 1
        round = 0
    }

    if (data === "listings") {
        icon = "fas fa-shopping-basket"
        unit = "Millions"
        text = text || "Listings"
        timeRange = TimeRange
        conversion = 1000000
        round = 3
    }

    if (data === "best_price_listings") {
        icon = "fas fa-check-circle"
        unit = "Millions"
        text = text || "Best Price Listings"
        timeRange = TimeRange
        conversion = 1000000
        round = 3
    }

  return (
    <div className="metric">
        <div className="metric-top">
            <h2><i className={icon}></i>{text} ({unit})</h2>
            {time_choice && (
            <h3 onClick={toggleTimeRange}>{timeRange}<i className="fas fa-sort-down"></i>
            {showTimeRange && <div className="time-range">
                <div onClick={() => {setTimeRange("Today"); toggleTimeRange()}} className="time-range-choice">Today</div>
                <div onClick={() => {setTimeRange("Last 7 Days"); toggleTimeRange()}} className="time-range-choice">Last 7 Days</div>
                <div onClick={() => {setTimeRange("Last 30 Days"); toggleTimeRange()}} className="time-range-choice">Last 30 Days</div>
                <div onClick={() => {setTimeRange("Last 90 Days"); toggleTimeRange()}} className="time-range-choice">Last 90 Days</div>
                </div>}
            
            </h3>)}
            
        </div>
        <div className="metric-bottom">
            <div className="value">{(value / conversion).toFixed(round)}</div>
            <div className="variation">{variation}</div>
        </div>
    </div>
  )
}

export default DashboardItemMetric