import React from 'react'
import useFetch from '../api/useFetch';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Loading from './Loading';
import usePost from '../api/usePost';
import { Link } from 'react-router-dom';

const Supplier = ({notify}) => {
    const { id } = useParams();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { result: supplier, isLoading: supplierLoading, error: supplierError } = useFetch(`/supplier/${id}`,[formSubmitted]);
    const { postData, error } = usePost();
    const [formData, setFormData] = useState({supplier_id: id, latency: '', blacklisted: '', extra_shipping_cost: ''});
    useEffect(() => {
        if (supplier) {
        setFormData({supplier_id: id, latency: supplier.latency || '', blacklisted: supplier.blacklisted || false, extra_shipping_cost: supplier.extra_shipping_cost || ''});
}}, [supplier, id]);

      const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await postData('/update_supplier', formData);
        console.log(response)
        if (response) {
            notify(response.message)
            setFormSubmitted(!formSubmitted)
        }
        if (error) {
            notify(error.message)
        }
      };

      const handleChange = (e) => {
        console.log('before', formData)
        const { name, value, type, checked } = e.target;
        console.log(name, value, type, checked)
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
        console.log('after', formData)
    };


  return (
    <>
    {supplierLoading && <><Loading /></>}
    {supplierError && <div>{supplierError}</div>}
    {!supplierLoading && supplier && (<>
        <div class="product-infos">
            <div class="main-product-infos">
                <center>
                <Link to="/suppliers">Back to suppliers list</Link>
                <h1 style={{marginTop: '20%'}}>{supplier.name}</h1>
                <form>
                    <label style={{fontWeight: 'bold'}}>Blacklisted</label>
                    <br />
                    <input style={{textAlign: 'center'}} type="checkbox" name="blacklisted" placeholder="Blacklisted" checked={formData.blacklisted} onChange={handleChange} />
                    <br />
                    <br />
                        <label style={{fontWeight: 'bold'}}>Extra Shipping Cost</label>
                    <br />
                    <input style={{textAlign: 'center'}} type="text" name="extra_shipping_cost" placeholder="Extra Shipping Cost" value={formData.extra_shipping_cost} onChange={handleChange} />
                    <br />
                    <br />
                    <label style={{fontWeight: 'bold'}}>Latency</label>
                    <br />
                    <input style={{textAlign: 'center'}} type="text" name="latency" placeholder="Latency" value={formData.latency} onChange={handleChange} />
                    <br />
                    <br />
                </form>
                <button onClick={handleSubmit} style={{marginTop: '15%', fontWeight: 'bold', backgroundColor: '#00253b', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer'}}>Submit</button>
                </center>
            </div>
        </div>
    </>)}   
    </>
  )
}

export default Supplier