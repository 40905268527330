import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from '../api/useFetch';
import SearchResultsContext from '../context/SearchTermContext';
import OrderLine from './OrderLine';
import '../css/orders.css';
import Loading from './Loading';


function SearchResults() {
    const { searchTerm } = useContext(SearchResultsContext);
    const { result: searchResults, isLoading, error } = useFetch(`/search?query=${searchTerm}`);
    const navigate = useNavigate();

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!searchResults || searchResults.length === 0) {
    return <div>No results found</div>;
  }

  if (searchResults.length === 1) {
    let id = searchResults[0].id
    navigate(`/order/${id}`);
  }

  return (
    <>
    <div className="orders">
    <div className="title">
      ORDERS
    </div>
    <div className="table">
      <div className="table-header">
      <div className="domain">MarketPlace</div>
        <div className="date">Order Date</div>
        <div className="order_id">Order Id</div>
        <div className="product">Product</div>
        <div className="sourcing">Supplier</div>
        <div className="destination">Destination</div>
        <div className="price">Price</div>
        <div className="status">Status</div>
        <div className="status">Ext Status</div>
        <div className="action">Details</div>
      </div>
      <div className="table-body">
      {isLoading && <Loading />}
      {error && <div>{error}</div>}
      {!isLoading && searchResults && searchResults.map((order) => (
         <OrderLine 
         key={order.id}
         id={order.id}
         marketplace_name={order.marketplace.name}
         marketplace_domain={order.marketplace.domain}
         order_date={order.purchase_date}
         order_id={order.marketplace_order_id}
         product_name={order.is_multiproducts ? 'Multiproducts' : (order.products[0].product.name.length > 75 ? order.products[0].product.name.substring(0,70).concat(" (...)") : order.products[0].product.name) }
         product_sourcing_marketplace={order.is_multiproducts ? 'Multiproducts' : order.products[0].product.sourcing_marketplace.name}
         detination_country={order.destination_country}
         net_revenue={order.net_revenue_usd}
         internal_status={order.internal_status}
         marketplace_status={order.marketplace_status}
         />
        ))}
      </div>
  </div>
  </div>
  </>
  );
}

export default SearchResults;
