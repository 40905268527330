import useFetch from '../api/useFetch';
import ShippingPriceForm from './ShippingPriceForm';
import { useState } from 'react';
import usePost from '../api/usePost';
import Loading from './Loading'
import ShippingFormulaForm from './ShippingFormulaForm';

const Shipping = ({notify}) => {
  const [unit, setUnit] = useState('kilos');
  const [shippingFormVisible, setShippingFormVisible] = useState(false);
  const [shippingFormulaFormVisible, setShippingFormulaFormVisible] = useState(false);
  const [destination, setDestination] = useState(null)
  const [View, setView] = useState('new_shipment')
  const [selectedCost,setSelectedCost] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false);

  const pound_to_kilo = 0.453592

  const { result: shipping_costs, isLoading: shippingLoading, error: shippingError } = useFetch(`/shipping`,[formSubmitted]);

  const changeUnit = () => {
    unit === 'pounds' ? setUnit('kilos') : setUnit('pounds')
  }

  const openShipmentForm = (cost) => {
    setSelectedCost(cost)
    setShippingFormVisible(true);
  }
  const openShipmentFormulaForm = () => {
    setShippingFormulaFormVisible(true);
  }
  const handleViewClick = (view) => {
    setView(view)
  }

  const { postData, isLoading, error } = usePost();
  const [formData, setFormData] = useState({tracking_number: '', cost: '', currency: 'EUR', order_ids: []});
  const handleChange = (e) => {
      const { name, value } = e.target;
      let fieldValue = value;
  if (name === 'order_ids') {
    // Split only for the "orders" field
    fieldValue = value.split(/[ ,]+/);
    // Remove empty strings from the array
    fieldValue = fieldValue.filter((order) => order.trim() !== '');
  }
  setFormData({ ...formData, [name]: fieldValue });
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await postData('/create_purchase_multi_shipment', formData);
      if (response) 
        setFormSubmitted(!formSubmitted)
        notify('Shipment Saved')
      }

  return (
    <>
  <div class="main-infos">
            <div class={`main-info ${View === 'new_shipment' ? 'active' : ''}`} onClick={() => handleViewClick('new_shipment')}>
            <i className="fas fa-info-circle"></i>Create Shipment
            </div>
            <div class={`main-info ${View === 'shipping_costs' ? 'active' : ''}`} onClick={() => handleViewClick('shipping_costs')}>
            <i className="fas fa-headset"></i>Shipping Costs
            </div>
  </div>
  {View === 'new_shipment' && (
    <><div id="new-shipment-form">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="orders">Orders:</label>
          <input type="text" id="order_ids" name="order_ids" value={formData.order_ids} style={{ width: '300px', height: '40px' }}  onChange={handleChange}/>
          <br />
          <label htmlFor="tracking-number">Tracking Number:</label>
          <input type="text" id="tracking-number" name="tracking_number" value={formData.tracking_number} onChange={handleChange}/>
          <br />
          <label htmlFor="cost">Cost:</label>
          <input type="text" id="cost" name="cost" value={formData.cost} onChange={handleChange}/>
          <br />
          <select id="currency" name="currency" value={formData.currency} onChange={handleChange}>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
          <button type="submit">
            <i className="fas fa-save" disabled={isLoading}></i>Save
          </button>
        </div>
      </form>
    {error && <p>Error: {error}</p>}
  </div>

    </>
  )}
  {View === 'shipping_costs' && (
    <>
  <div div className="title">
      DESTINATION    <br/><br/><br/>
    {!shippingLoading &&
              shipping_costs &&
              shipping_costs.countries.map((country) => (<img height="20px"alt={country.name} onClick={() => setDestination(country)} src={country.flag_url}></img>))}
    </div>
      <div className="switch-container">
        <div className="toggle-switch">
          <input type="checkbox" className="checkbox" 
                name='unit' id='unit' />
          <label onClick={changeUnit} className="label" htmlFor='unit'>
            <span className="inner" />
            <span className="switch" />
          </label>
        </div>
      </div>
      <div class="main-infos">
            <div class='main-info active' onClick={openShipmentFormulaForm}>
            <i className="fas fa-plane-departure"></i>New Shipping Formula
            </div>
      </div>
      <div className="products">
    <div className="table">
      <div className="table-header">
        <div className="large">Origin</div>
        <div className="large">Destination</div>
        <div className="small">Min Weight</div>
        <div className="small">Max Weight</div>
        <div className="small">Cost USD</div>
        <div className="small">Edit</div>
      </div>
      <div className="table-body">
              {shippingLoading && <div className='table-placeholder'><Loading /></div>}
              {shippingError && <div>{shippingError}</div>}
              {!shippingLoading &&
                shipping_costs &&
                shipping_costs.shipping_costs.map((shipping_cost) => ( (!destination || (destination.id === shipping_cost.destination.id)) &&
                  <div className="table-row">
                  <div className="large">{shipping_cost.origin.name}</div>
                  <div className="large">{shipping_cost.destination.name}</div>
                  <div className="small">{unit === 'kilos' ? parseFloat(shipping_cost.min_weight).toFixed(1) : parseFloat(shipping_cost.min_weight / pound_to_kilo).toFixed(1)}</div>
                  <div className="small">{unit === 'kilos' ? parseFloat(shipping_cost.max_weight).toFixed(1) : parseFloat(shipping_cost.max_weight / pound_to_kilo).toFixed(1)}</div>
                  <div className="small">${shipping_cost.cost_usd}</div>
                  <div onClick={() => openShipmentForm(shipping_cost)} className="small"><i class="fas fa-edit"></i></div>
                  </div>
                ))}
            </div>
      </div>
    </div></>)}
    {shippingFormVisible &&<ShippingPriceForm  shippingFormVisible={shippingFormVisible} setShippingFormVisible={setShippingFormVisible} selectedCost={selectedCost} setFormSubmitted={setFormSubmitted} formSubmitted={formSubmitted}/>}
   {shippingFormulaFormVisible &&<ShippingFormulaForm shippingFormulaFormVisible={shippingFormulaFormVisible} setShippingFormulaFormVisible={setShippingFormulaFormVisible} countries={shipping_costs.countries} setFormSubmitted={setFormSubmitted} formSubmitted={formSubmitted}/>}
    </>
  )
}

export default Shipping