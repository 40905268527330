import usePost from '../api/usePost';
import { useState } from 'react';

function OrderUpdateForm({ notify, visible, setVisible, order, setFormSubmitted, formSubmitted }) {
  const { postData, isLoading, error } = usePost();
  const [formData, setFormData] = useState({
    order_id: order.id,
    internal_status: order.internal_status,
    received: order.is_received, // Initialize with boolean value
    shipped: order.is_shipped,   // Initialize with boolean value
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handle checkboxes separately
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await postData('/update_order', formData);
    if (response) {
      setFormSubmitted(!formSubmitted);
      setVisible(false);
      notify('Order Updated');
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    visible && (
      <div id="order-form">
        <i id="close-order-form" className="fas fa-times" onClick={handleClose}></i>
        <form onSubmit={handleSubmit}>
          <div>
            <select
              id="internal_status"
              name="internal_status"
              value={formData.internal_status}
              onChange={handleChange}
            >
              <option value="to_buy">Placed By Client - To Buy</option>
              <option value="pending_sourcing">Pending - Sourcing Issue</option>
              <option value="pending_purchase_shipment">Pending Purchase Shipment</option>
              <option value="pending_purchase_reception">Pending Purchase Reception</option>
              <option value="to_pickup">To Pick Up</option>
              <option value="received">Received</option>
              <option value="partly_received">Partly Received</option>
              <option value="shipped">Shipped</option>
              <option value="anticipated_shipping">Pre-Shipped</option>
              <option value="dummy_shipping">Dummy-Shipped - Sourcing Issue</option>
              <option value="dummy_shipping_kept">Dummy-Shipped - Kept in Inventory</option>
              <option value="received_by_client">Received by Client</option>
              <option value="canceled_by_client">Canceled by Client</option>
              <option value="canceled_by_us">Canceled - No Sourcing</option>
              <option value="canceled_by_marketplace">Canceled by Marketplace - No Shipment</option>
              <option value="return_received">Return Received</option>
            </select>
            <div>
              <label htmlFor="received">Received</label>
              <input
                type="checkbox"
                id="received"
                name="received"
                checked={formData.received}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="shipped">Shipped</label>
              <input
                type="checkbox"
                id="shipped"
                name="shipped"
                checked={formData.shipped}
                onChange={handleChange}
              />
            </div>
            <button type="submit">
              <i className="fas fa-save" disabled={isLoading}></i>Save
            </button>
          </div>
        </form>
        {error && <p>Error: {error}</p>}
      </div>
    )
  );
}

export default OrderUpdateForm;
