import usePost from '../api/usePost';
import { useState } from 'react';

function FeedbackForm({notify, visible, setVisible, orderId, setFormSubmitted, formSubmitted, isMulti}) {
  const { postData, isLoading, error } = usePost();
  const [formData, setFormData] = useState({order_id: orderId, feedback_rating: '', feedback_comment: ''});
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      console.log(formData)
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await postData('/create_feedback', formData);
      if (response) {
        setFormSubmitted(!formSubmitted);
        setVisible(false);
        notify('Feedback Saved')
      }
    };
    const handleClose = () => {
        setVisible(false);
      };
  return (
    visible && (<div id="feedback-form">
    <i id="close-feedback-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
      <div>
      <select id="feedback-rating" name="feedback_rating" value={formData.feedback_rating || "1"} onChange={handleChange}>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
        <br />
        <br />
        <label htmlFor="feedback_comment">Feedback Comment:</label>
        <input type="text" id="feedback-comment" name="feedback_comment" value={formData.feedback_comment} onChange={handleChange}/>
        <br />
        <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Save
        </button>
      </div>
    </form>
    {error && <p>Error: {error}</p>}
  </div>)
  )
}

export default FeedbackForm