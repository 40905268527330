import {useState} from 'react'
import usePost from '../api/usePost';

const ShippingPriceForm = ({shippingFormVisible, setShippingFormVisible, selectedCost, setFormSubmitted, formSubmitted}) => {
    const { postData, isLoading, error } = usePost();
    const [formData, setFormData] = useState({shipping_cost_id: selectedCost.id, minimum_weight: selectedCost.min_weight, maximum_weight: selectedCost.max_weight, cost: selectedCost.cost_usd});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        console.log(formData)
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await postData('/update_shipping_cost', formData);
    
        if (response) {
            // setFormSubmitted(!formSubmitted);
            setShippingFormVisible(false);
            setFormSubmitted(!formSubmitted)

        }
      };

    const handleClose = () => {
        setShippingFormVisible(false);
      };

  return (
    <>
    {shippingFormVisible && (<><div id="shipment-form">
    <i id="close-shipment-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="tracking-number">Minimum Weight (kg):</label>
        <input type="text" id="minimum-weight" name="minimum_weight" value={formData.minimum_weight} onChange={handleChange} />
        <br />
        <label htmlFor="tracking-number">Maximum Weight (kg):</label>
        <input type="text" id="maximum-weight" name="maximum_weight" value={formData.maximum_weight} onChange={handleChange} />
        <br />
        <label htmlFor="tracking-number">Cost (USD):</label>
        <input type="text" id="cost" name="cost" value={formData.cost} onChange={handleChange} />
        <br />
        <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Save
        </button>
      </div>
    </form>
    {error && <p>Error: {error}</p>}
  </div>
  <div className="bg-disabled"></div>
  </>)}
  </>
  )
}

export default ShippingPriceForm