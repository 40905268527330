import { useRef, useState, useEffect, useContext } from 'react'
import AuthContext from '../context/AuthProvider';
import '../css/login.css';
import Logo from '../static/img/epytis_logo.png';

import axios from '../api/axios';

const LOGIN_URL = 'https://api.epytis.com/api/login/'



const Login = () => {
    const { auth, setAuth } = useContext(AuthContext)
    const userRef = useRef()
    const errRef = useRef()

    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState(false)

    useEffect(() => {
        console.log(auth);
      }, [auth]);

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            console.log(LOGIN_URL)
            console.log(JSON.stringify({"username": user,"password": pwd}))
            const response = await axios.post(LOGIN_URL, JSON.stringify({"username": user,"password": pwd}), {headers: {'Content-Type': 'application/json'}, withCredentials: true})
            console.log(response)
            console.log(response?.data)
            const { access_token, refresh_token, first_name, last_name, profile_picture_url } = response.data;
            console.log(access_token)
            setAuth({accessToken: access_token, refreshToken: refresh_token, firstName: first_name, lastName: last_name, profilePictureURL: profile_picture_url})
            localStorage.setItem("accessToken", access_token);
            localStorage.setItem("refreshToken", refresh_token);
            localStorage.setItem("firstName", first_name);
            localStorage.setItem("lastName", last_name);
            localStorage.setItem("profilePictureURL", profile_picture_url);
            setUser('')
            setPwd('')
            setErrMsg(false)
        } catch (err) {
            console.log(err)
            if(!err?.response) {
                setErrMsg('No Server Response')
            } else if (err.response?.status === 400 ){
                setErrMsg('Invalid Login or Password')
            }
            else {
                setErrMsg('Login Failed')
            }
            errRef.current.focus()
        }

    } 

  return (
    <div className="container">
        <div className="image-box-container">
            <img src={Logo} alt="Epytis Logo" />
            <div className="box">
                <div className="icon-container">
                    <i className="fas fa-user icon"></i>
                </div>
                <p><b>Login</b></p>
                <p>Please fill in the details below to connect</p>
                <p ref={errRef} className={errMsg ? "error" : "hidden"} aria-live="assertive">{errMsg ? errMsg : ""}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <div className="form-control">
                            <input type="text" placeholder="Username" id='username' ref={userRef} autoComplete='off' required
                                onChange= {(e) => setUser(e.target.value)}
                                value={user}
                            />
                            <i className="fas fa-user form-icon"></i>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-control">
                            <input type="password" placeholder="Password" id='password' required
                                onChange= {(e) => setPwd(e.target.value)}
                                value={pwd}
                            />
                            <i className="fas fa-lock form-icon"></i>
                        </div>
                    </div>
                    <button>Sign In</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Login