import useFetch from "../api/useFetch";
import Loading from "./Loading";
import RouteLine from "./RouteLine";
import '../css/products.css'

const Routes = () => {
  const { result: routes, isLoading: routesLoading, error: routesError } = useFetch(`/routes`, []);

  return (
    <div className="products">
      <div className="table">
        <div className="table-header">
          <div className="table-row">
            <div className="small">Sourcing</div>
            <div className="small">Destination</div>
            <div className="small">Segment</div>
            <div className="small">Status</div>
            <div className="small">Algorithm</div>
            <div className="small">Deployed</div>
            <div className="small">Products</div>
            <div className="medium">Available</div>
            <div className="small">Listings</div>
            <div className="small">Listings C</div>
            <div className="small">Best Price</div>
            <div className="small">Competition Ratio</div>
            <div className="small">Sales Count</div>
            <div className="small">Product With Sales</div>
          </div>
        </div>

        <div className="table-body">
          {routesLoading && <div className='table-placeholder'><Loading /></div>}
          {routesError && <div>{routesError}</div>}

          {/* Single line of aggregated totals */}
          {!routesLoading && routes && (() => {
            const totals = routes.reduce((acc, route) => {
              acc.products += route.products_count || 0;
              acc.products_available += route.products_available_count || 0;
              acc.listings += route.listings_count || 0;
              acc.listings_with_competition_info += route.listings_with_competition_info || 0;
              acc.best_price += route.listings_best_price_count || 0;
              acc.sales_count += route.sales_count || 0;
              acc.products_with_sales += route.products_with_sales || 0;
              acc.competition_ratio += route.competition_ratio_average || 0;
              acc.count += 1;
              return acc;
            }, {
              products: 0,
              products_available: 0,
              listings: 0,
              listings_with_competition_info: 0,
              best_price: 0,
              sales_count: 0,
              products_with_sales: 0,
              competition_ratio: 0,
              count: 0
            });

            return (
              <RouteLine
                key="total"
                id="total"
                sourcing="Martelle"
                destination="amazon.com"
                segment="-"
                status="-"
                algorithm="-"
                deployed="-"
                products={totals.products}
                products_available={totals.products_available}
                listings={totals.listings}
                listings_with_competition_info={totals.listings_with_competition_info}
                best_price={totals.best_price}
                competition_ratio="-"
                sales_count={totals.sales_count}
                products_with_sales={totals.products_with_sales}
              />
            );
          })()}

          {/* Original routes listing */}
          {!routesLoading && routes && routes.map((route) => (
            <RouteLine
              id={route.id}
              key={route.id}
              sourcing={route.sourcing_marketplace.name}
              destination={route.destination_marketplace.domain}
              segment={route.segment}
              status={route.status}
              algorithm={route.pricing_algorithm}
              deployed={route.deployed ? 'yes' : 'no'}
              products={route.products_count}
              products_available={route.products_available_count}
              listings={route.listings_count}
              listings_with_competition_info={route.listings_with_competition_info}
              best_price={route.listings_best_price_count}
              competition_ratio={route.competition_ratio_average}
              sales_count={route.sales_count}
              products_with_sales={route.products_with_sales}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Routes;
