import { Link } from 'react-router-dom';


const ProductLine = ({
    id,
    ean,
    asin,
    product_name,
    nb_orders,
    total_revenue,
    total_margin,
    has_inventory,
    blacklisted,
    has_listings,
    segment
}) => {



  return (
    <div className="table-row">
      <div className="small">{ean}</div>
      <div className="small">{asin}</div>
      <div className="small">{segment}</div>
      <div className="large">{product_name.length > 60 ? product_name.substring(0, 60).concat(" (...)") : product_name}</div>
      <div className="small">{nb_orders}</div>
      <div className="small">${total_revenue}</div>
      <div className="small">${total_margin}</div>
      <div className="small">{has_inventory}</div>
      <div className="small">{has_listings}</div>
      <div className="small">{blacklisted}</div>
      <div className="action">
        <Link to={`/product/${id}`}>
          <i className="fas fa-eye"></i>
        </Link>
      </div>
    </div>
  );
};

export default ProductLine;
