const TransactionLine = ({
    id,
    type,
    description,
    settlement,
    amount,
    currency
}) => {

  return (
    <div className="table-row">
      <div className="type">{type}</div>
      <div className="description">{description}</div>
      <div className="settlement">{settlement}</div>
      <div className="amount">{amount}</div>
      <div className="currency">{currency}</div>
    </div>
  );
};

export default TransactionLine;
