import { Link } from 'react-router-dom';


const SupplierLine = ({
  id,
  dilicom_id,
  supplier_name,
  latency,
  extra_cost
}) => {



  return (
    <div className="table-row">
      <div className="small">{dilicom_id}</div>
      <div className="large">{supplier_name}</div>
      <div className="small">{latency}</div>
      <div className="small">{extra_cost}</div>
      <div className="action">
        <Link to={`/supplier/${id}`}>
          <i className="fas fa-eye"></i>
        </Link>
      </div>
    </div>
  );
};

export default SupplierLine;
