import {useState} from 'react'
import usePost from '../api/usePost';

const ShippingFormulaForm = ({shippingFormulaFormVisible, setShippingFormulaFormVisible, countries, setFormSubmitted, formSubmitted}) => {
    const { postData, isLoading, error } = usePost();
    const [formData, setFormData] = useState({origin: '', destination: '', min_weight: '', max_weight: '', base_price: '', increment_price: '', step: '', delete: false});
    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await postData('/create_shipping_formula', formData);
    
        if (response) {
            setShippingFormulaFormVisible(false);
            setFormSubmitted(!formSubmitted)
        }
      };

    const handleClose = () => {
      setShippingFormulaFormVisible(false);
      };

  return (
    <>
    {shippingFormulaFormVisible && (<><div id="comment-form">
    <i id="close-shipment-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
      <div>
        Shipping Costs
        <select id="origin" name="origin" value={formData.origin} onChange={handleChange}>
        <option value="" disabled selected>Select the origin country</option>
          {countries.map((country) => (<option value={country.id}>{country.name}</option>))}
      </select>
        <select id="destination" name="destination" value={formData.destination} onChange={handleChange}>
        <option value="" disabled selected>Select the destination country</option>
          {countries.map((country) => (<option value={country.id}>{country.name}</option>))}
      </select>
        <div style={{ display: 'flex', alignItems: 'center' }}><label htmlFor="delete">Delete Prices</label><input type="checkbox" id="delete" name="delete" value={formData.delete} checked={formData.delete} onChange={handleChange} /></div>
        {formData.delete || <><input type="text" id="minimum-weight" name="min_weight" placeholder='Min Weight (kg)' value={formData.min_weight} onChange={handleChange} />
        <input type="text" id="maximum-weight" name="max_weight" placeholder='Max Weight (kg)' value={formData.max_weight} onChange={handleChange} />
        <input type="text" id="base-price" name="base_price" placeholder='Base Price (USD)' value={formData.base_price} onChange={handleChange} />
        <input type="text" id="increment-price" name="increment_price" placeholder='Increment Price (USD)' value={formData.increment_price} onChange={handleChange} />
        <input type="text" id="bstep" name="step" placeholder='Step (kg)' value={formData.step} onChange={handleChange} /></>}
        <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Save
        </button>
      </div>
    </form>
    {error && <p>Error: {error}</p>}
    </div>
    <div className="bg-disabled"></div>
      </>
)}
  
  </>
  )
}

export default ShippingFormulaForm