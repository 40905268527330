import usePost from '../api/usePost';
import { useState } from 'react';

function CommentForm({notify, visible, setVisible, order, setFormSubmitted, formSubmitted}) {
  let comment = order.comment || ''
  let action = order.has_action || false
  const { postData, isLoading, error } = usePost();
  const [formData, setFormData] = useState({order_id: order.id, comment: comment, action: action});
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    };


  const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await postData('/create_comment', formData);
      if (response) {
        setFormSubmitted(!formSubmitted);
        setVisible(false);
        notify('Comment Saved')
      }
    };

  const handleClose = () => {
        setVisible(false);
      };

  return (
    visible && (<div id="comment-form">
    <i id="close-comment-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="comment">Comment:</label>
        <br />
        <textarea id="comment" name="comment" style={{ width: '300px', height: '400px' }} value={formData.comment} onChange={handleChange}/>
        <br />
        <div>
          <label htmlFor="received">Action Needed?</label>
          <input type="checkbox" id="action" name="action" checked={formData.action} onChange={handleChange} />
        </div>
      </div>
      <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Save
        </button>
    </form>
    {error && <p>Error: {error}</p>}
  </div>)
  )
}

export default CommentForm