import { Link } from 'react-router-dom';
import usePost from '../api/usePost';
import { useState } from 'react';

const ScriptLine = ({
  id,
  name,
  frequency,
  has_errors,
  last_run_date,
  last_run_time,
  notify
}) => {
  const { postData } = usePost();
  const [isRunning, setIsRunning] = useState(false);

  const runScript = async (id) => {
    setIsRunning(true);
    const response = await postData(`/run_script`, { script_id: id });
    if (response && !Object.hasOwn(response, 'error')) {
      notify(response.message)
      setIsRunning(false)
  }
  if (Object.hasOwn(response, 'error')) {
      notify(response.error,"error")
      setIsRunning(false)
  }
    
  };

  return (
    <div className="table-row" data-id={id}>
      <div className="large">{name}</div>
      <div className="small">{frequency}</div>
      <div className="small">
        {frequency === 'stopped' ? (
          <i className="fas fa-stop-circle yellow"></i>
        ) : has_errors ? (
          <i className="fas fa-exclamation-triangle red"></i>
        ) : (
          <i className="fas fa-check-square green"></i>
        )}
      </div>
      <div className="small">{last_run_date || 'Never'}</div>
      <div className="small">{last_run_time || 'Never'}</div>
      <div className="small">
        <i 
          onClick={() => !isRunning && runScript(id)} 
          className={`fas fa-play ${isRunning ? 'disabled' : ''}`}
        ></i>
      </div>
      <div className="small">
        <Link to={`/script/${id}`}>
          <i className="fas fa-eye"></i>
        </Link>
      </div>
    </div>
  );
};

export default ScriptLine;