import usePost from '../api/usePost';
import { useState } from 'react';

function ScriptForm({notify, visible, setVisible, script=null, setFormSubmitted, formSubmitted}) {
    const { postData, isLoading, error } = usePost();
    let script_id = script ? script.id : ''
    let name = script ? script.name : ''
    let command = script ? script.command : ''
    let description = script ? script.description : ''
    let category = script ? script.category : ''
    let frequency = script ? script.frequency : ''
    const [formData, setFormData] = useState({name: name, command: command, description: description, category: category, frequency: frequency, script_id: script_id});
    
    const handleClose = () => {
        setVisible(false);
      };

    const handleChange = (e) => {
      const { name, value} = e.target;
      setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await postData('/create_script', formData);
        if (response) {
            setFormSubmitted(!formSubmitted);
            setVisible(false);
                notify('Script Created Succesfully')
        }
      };

  return (
    visible && (
    <div id="script-form">
    <i id="close-script-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
        <input type="hidden" id="script-id" name="script_id" value={formData.script_id} />
        <div>
          <label htmlFor="script-name">
            <i className="fas fa-file-alt"></i>Script Name:
          </label>
          <input type="text" id="script-name" name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="script-command">
            <i className="fas fa-terminal"></i>Script Command:
          </label>
          <input type="text" id="script-command" name="command" value={formData.command} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="script-description">
            <i className="fas fa-file-alt"></i>Script Description:
          </label>
          <input type="text" id="script-description" name="description" value={formData.description} onChange={handleChange} /> 
        </div>
        <div>
          <label htmlFor="script-category">
            <i className="fas fa-file-alt"></i>Script Category:
          </label>
          <select id="script-category" name="category" value={formData.category} onChange={handleChange}>
            <option value="shipping">Shipping</option>
            <option value="orders">Orders</option>
            <option value="inventory">Inventory</option>
            <option value="finance">Finance</option>
            <option value="customer_service">Customer Service</option>
            <option value="customer_service">Reporting</option>
          </select>
        </div>  
        <div>
          <label htmlFor="script-frequency">
            <i className="fas fa-file-alt"></i>Script Frequency:
          </label>
          <select id="script-frequency" name="frequency" value={formData.frequency} onChange={handleChange}>
            <option value="maximal">Maximal</option>
            <option value="hourly">Hourly</option>
            <option value="daily">Daily</option>
            <option value="stopped">Stopped</option>
          </select>
        </div>  
        <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Save
        </button>
    </form>
    {error && <p>Error: {error}</p>}
  </div>)
  )
}

export default ScriptForm