import usePost from '../api/usePost';
import { useState } from 'react';

function CustomerShipmentForm({notify, visible, setVisible, orderId, setFormSubmitted, formSubmitted}) {
  const { postData, isLoading, error } = usePost();
  const [formData, setFormData] = useState({order_id: orderId, tracking_number: '', shipment_type: 'normal'});
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      console.log(formData)
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await postData('/create_order_shipment', formData);
  
      if (response) {
        setFormSubmitted(!formSubmitted);
        setVisible(false);
        notify('Shipment Saved')
      }
    };
    const handleClose = () => {
        setVisible(false);
      };
  return (
    visible && (<div id="customer-shipment-form">
    <i id="close-shipment-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="tracking-number">Tracking Number:</label>
        <input type="text" id="tracking-number" name="tracking_number" value={formData.tracking_number} onChange={handleChange}/>
        <br />
        <label htmlFor="tracking-number">Cost USD:</label>
        <input type="text" id="cost_usd" name="cost_usd" value={formData.cost_usd} onChange={handleChange}/>
        <br />
        <label htmlFor="shipment-type">Shipment Type:</label>
            <select id="shipment-type" name="shipment_type" value={formData.shipment_type} onChange={handleChange}>
                <option value="normal">Normal</option>
                <option value="anticipated">Anticipated</option>
                <option value="dummy">Dummy</option>
            </select>
        <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Save
        </button>
      </div>
    </form>
    {error && <p>Error: {error}</p>}
  </div>)
  )
}

export default CustomerShipmentForm