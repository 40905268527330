import React from 'react'

const RouteLine = ({id, sourcing, destination, segment, status, algorithm, deployed, products, products_available, listings, listings_with_competition_info, best_price, competition_ratio, sales_count, products_with_sales}) => {
  return (
    <div className="table-row" data-id={id}>
      <div className="small">{sourcing}</div>
      <div className="small">{destination}</div>
      <div className="small">{segment}</div>
      <div className="small">{status}</div>
      <div className="small">{algorithm}</div>
      <div className="small">{deployed}</div>
      <div className="small">{products}</div>
      <div className="medium">{products_available} ({(products_available/products * 100).toFixed(1)}%)</div>
      <div className="small">{listings} ({(listings/products_available * 100).toFixed(1)}%)</div>
      <div className="small">{listings_with_competition_info} ({(listings_with_competition_info/listings * 100).toFixed(1)}%)</div>
      <div className="small">{best_price} ({(best_price/listings_with_competition_info * 100).toFixed(1)}%)</div>
      <div className="small">{competition_ratio}</div>
      <div className="small">{sales_count}</div>
      <div className="small">{products_with_sales}</div>
    </div>
  )
}

export default RouteLine