import useFetch from "../api/useFetch";
import Loading from "./Loading";
import ScriptLine from "./ScriptLine";
import ScriptForm from './ScriptForm'
import { useState } from 'react';
import '../css/products.css'


function Scripts({ notify }) {
    const { result: scripts, isLoading: scriptsLoading, error: scriptsError } = useFetch(`/scripts`,[]);
    const [View, setView] = useState('all');
    const [scriptFormVisible, setScriptFormVisible] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const handleViewClick = (view) => {
      setView(view);
    };
  return (
    <>
    <div class="main-infos">
      <div class={`main-info ${View === 'shipping' ? 'active' : ''}`} onClick={() => View === 'shipping' ? handleViewClick('all') : handleViewClick('shipping')}><i className="fas fa-sync"></i>Shipping</div>
      <div class={`main-info ${View === 'orders' ? 'active' : ''}`} onClick={() => View === 'orders' ? handleViewClick('all') :  handleViewClick('orders')}><i className="fas fa-sync"></i>Orders</div>
      <div class={`main-info ${View === 'inventory' ? 'active' : ''}`} onClick={() => View === 'inventory' ? handleViewClick('all') :  handleViewClick('inventory')}><i className="fas fa-cash-register"></i>Inventory</div>
      <div class={`main-info ${View === 'finance' ? 'active' : ''}`} onClick={() => View === 'finance' ? handleViewClick('all') :  handleViewClick('finance')}><i className="fas fa-comments"></i>Finance</div>
      <div class={`main-info ${View === 'customer_service' ? 'active' : ''}`} onClick={() => View === 'customer_service' ? handleViewClick('all') :  handleViewClick('customer_service')}><i className="fas fa-info"></i>Customer Service</div>
      <div class={`main-info ${View === 'reporting' ? 'active' : ''}`} onClick={() => View === 'reporting' ? handleViewClick('all') :  handleViewClick('reporting')}><i className="fas fa-info"></i>Reporting</div>
    </div>
    <div class="main-infos">
      <div class='main-info active' onClick={() => setScriptFormVisible(true)}><i className="fas fa-info-circle"></i>Create New Script</div>
    </div>
      <div className="products">
      <div className="table">
        <div className="table-header">
          <div className="table-row">
            <div className="large">Name</div>
            <div className="small">Frequency</div>
            <div className="small">Status</div>
            <div className="small">Last Run Date</div>
            <div className="small">Last Run Time</div>
            <div className="small">Run Now</div>
            <div className="small">Details</div>
          </div>
        </div>
        
        <div className="table-body">
                {scriptsLoading && <div className='table-placeholder'><Loading /></div>}
                {scriptsError && <div>{scriptsError}</div>}
                {!scriptsLoading &&
                  scripts &&
                  scripts.filter((script) => View === 'all' || script.category === View).map((script) => (
                    <ScriptLine
                    key={script.name}
                    name= {script.name}
                    frequency={script.frequency}  
                    has_errors={script.has_unsolved_errors}
                    last_run_date={script.last_run && script.last_run.substring(0,10)}
                    last_run_time={script.last_run && script.last_run.substring(11,19).split(':')[0] - 4 + ':' + script.last_run.substring(11,19).split(':')[1]}
                    id={script.id}
                    notify={notify}
                    />
                  ))}
              </div>
        </div>
      </div>
      {scriptFormVisible && (<><ScriptForm notify = {notify} visible={scriptFormVisible} setVisible={setScriptFormVisible} setFormSubmitted={setFormSubmitted} formSubmitted={formSubmitted}/><div className="bg-disabled"></div></>)}
   </>
  )
}

export default Scripts