import { Link } from 'react-router-dom';

const INTERNAL_STATUS = {
  to_buy: "Placed By Client - To Buy",
  pending_sourcing: "Sourcing Issue",
  pending_purchase_shipment: "Pending Purchase Shipment",
  pending_purchase_reception: "Pending Purchase Reception",
  to_pickup: "To Pick Up",
  received: "Received",
  partly_received: "Partly Received",
  anticipated_shipping: "Pre-Shipped",
  dummy_shipping: "Dummy-Shipped",
  shipped: "Shipped",
  received_by_client: "Received by Client",
  canceled_by_client: "Canceled by Client",
  canceled_by_us: "Canceled - No Sourcing",
  canceled_by_marketplace: "Canceled by Marketplace",
  dummy_shipping_kept: "Dummy-Shipped - Kept in Inventory",
  return_received: "Return Received",
  archived: "Archived"
};



const OrderLine = ({
    id,
    marketplace_domain,
    marketplace_api_id,
    order_date,
    order_id,
    product_name,
    product_id,
    product_sourcing_marketplace,
    detination_country,
    net_revenue,
    internal_status,
    marketplace_status,
    has_checkbox,
    selectedOrders,
    setSelectedOrders,
    feedback,
}) => {

  const addOrder = (id) => {
    setSelectedOrders([...selectedOrders, id]);
  };

  const removeOrder = (id) => {
    setSelectedOrders(selectedOrders.filter((orderId) => orderId !== id));
  };

  return (
    <div className="table-row">
      <div className="domain">{marketplace_domain}</div>
      <div className="date">{order_date}</div>
      <div className="order_id">{order_id}</div>
      {product_id && <div className="product"><Link to={`/product/${product_id}`}>{product_name}</Link></div>}
      {!product_id && <div className="product">{product_name}</div>}
      <div className="sourcing">{product_sourcing_marketplace}</div>
      <div className="destination">{detination_country}</div>
      <div className="price">${net_revenue}</div>
      <div className="status">{INTERNAL_STATUS[internal_status]}</div>
      <div className="status">{marketplace_status}</div>
      <div className="action">
        <Link to={feedback ? `https://sellercentral.amazon.com/messaging/reviews?orderId=${order_id}&marketplaceId=${marketplace_api_id}` : `/order/${id}`} target="_blank">
          <i className="fas fa-eye"></i>
        </Link>
      </div>
      {has_checkbox && !selectedOrders.includes(id) && (<div className="action" onClick={() => addOrder(id)}><i class="far fa-square"></i></div>)}
      {has_checkbox && selectedOrders.includes(id) && (<div className="action" onClick={() => removeOrder(id)}><i class="fas fa-check-square"></i></div>)}
    </div>
  );
};

export default OrderLine;
