import '../css/orders.css';
import useFetch from "../api/useFetch";
import usePost from '../api/usePost';
import { useState } from 'react';
import Updated from '../static/img/updated.png';
import { Link } from 'react-router-dom';
import Loading from './Loading';


function Inventory({ notify }) {
    const [refresh, setRefresh] = useState(false);
    const { postData, error } = usePost();
    const [inventoryLocation,setInventoryLocation] = useState('A1')
    const { result: inventory, isLoading: inventoryLoading, error: inventoryError } = useFetch(`/get_inventory?location=${inventoryLocation}`,[refresh, inventoryLocation]);
    const handleRefresh = () => {
      setRefresh(!refresh)
    }

  const handleChange = (event) => {
    setInventoryLocation(event.target.value);
  }

  const handleCheck = async (id) => {
    const data = {inventory_item_id: id}
    const response = await postData('/check_inventory_item', data);
    console.log('Response:', response);
      console.log('Error:', error);
    setRefresh(!refresh)
    notify('Inventory Item Marked as Checked')
  }


  const handleDelete = async (id) => {
    const data = {inventory_item_id: id}
    const response = await postData('/delete_inventory_item', data);
    console.log('Response:', response);
    console.log('Error:', error);
    setRefresh(!refresh)
    notify('Inventory Item Deleted')
  }

  const handleEdit = (id) => {
    console.log("edited")
    setRefresh(!refresh)
  }
  
  return (
    <>
      <div className="orders">
        <div className="title">
          INVENTORY
          <i className="fas fa-sync" onClick={handleRefresh}></i>
        </div>
        <div class="main-infos">
            <div class='main-info active'>
            <i className="fas fa-info-circle"></i>Add Inventory Item
            </div>
        </div>
        <br />
        <select id="origin" name="origin" value={inventoryLocation} onChange={(handleChange)}>
        <option value="" disabled selected>Select Location</option>
        {inventory && inventory.locations.map((location,index) => (<option  key={index} value={location}>{location === inventoryLocation ? `${location} - ${inventory.items.length} items` : location}</option>))}
      </select>
      <br />
        <div className="table">
          <div className="table-header">
            <div className="product">Product</div>
            <div className="date">Location</div>
            <div className="date">Acquisition Cost</div>
            <div className="date">Inventory Since</div>
            <div className="date">Last Check</div>
            <div className="date">Confirm</div>
            <div className="date">Edit</div>
            <div className="date">Delete</div>
          </div>
          <div className="table-body">
            {inventoryLoading && <div className='table-placeholder'><Loading /></div>}
            {inventoryError && <div>{inventoryError}</div>}
            {!inventoryLoading &&
              inventory && inventory.total > 0 &&
              inventory.items.map((item) => (
                <div className="table-row">
                <div className="product"><Link to={`/product/${item.product.id}`}>{item.product.name}</Link></div>
                <div className="date">{item.location}</div>
                <div className="date">{item.acquisition_cost}</div>
                <div className="date">{item.creation_date}</div>
                <div className="date">{item.last_check_date}</div>
                <div className="date"><i onClick={() => handleCheck(item.id)} className="fas fa-check"></i></div>
                <div className="date"><i onClick={() => handleEdit(item.id)} className="fas fa-edit"></i></div>
                <div className="date"><i onClick={() => handleDelete(item.id)} className="fas fa-trash"></i></div>
                </div>
              ))}
                        {!inventoryLoading &&
              inventory && inventory.total === 0 &&
              <img src={Updated} alt="" width="200px" id='updated'/>
              }
          </div>
        </div>
      </div>
    </>
  );  
}

export default Inventory