import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineChart = ({ chartData }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const formattedLabels = chartData.labels.map((label) => {
      console.log(label)
      const date = new Date(label);
      const options = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };
      return date.toLocaleString('en-US', options);
    });


    const chartConfig = {
      labels: formattedLabels,
      datasets: [
        {
          label: chartData.label,
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 2,
          pointRadius: 0,
          data: chartData.data,
          fill: false,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: false,
          grid: {
            display: false,
          },
          ticks: {
            stepSize: 50,
            callback: function (value, index, values) {
              return value;
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      layout: {
        padding: {
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
    };

    // Destroy previous chart instance if it exists
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Create new chart instance
    chartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: chartConfig,
      options: options,
    });

    // Cleanup function
    return () => {
      // Destroy chart instance on component unmount
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartData]);

  return (
    <div className="line-chart"> 
      <div className="chart-title">{chartData.title}</div>
      <canvas  ref={chartRef} width={1000} height={300} className='line-chart'></canvas>
    </div>
  );
};

export default LineChart;
