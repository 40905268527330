import React, { useState, createContext } from 'react';

const SidebarContext = createContext({
  collapsed: false,
  toggleCollapsed: () => {},
});

export const SidebarProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const contextValue = {
    collapsed,
    toggleCollapsed,
  };

  // Wrap children with SidebarContext.Provider and pass the contextValue
  return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
};

export default SidebarContext;
