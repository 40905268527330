// config.js
let API_BASE_URL;
console.log(window.location.hostname)
if (window.location.hostname === 'localhost') {
  API_BASE_URL = 'http://localhost:8000/api'
} else {
  API_BASE_URL = 'https://api.epytis.com/api';
}

export default API_BASE_URL;
